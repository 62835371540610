<route>
{
  "meta": {
    "permission": [
      "base_config.view_soldby"
    ]
  }
}
</route>

<template>
  <div>
    <dialsold v-model="dialog" :to-edit="toEdit" @done="reload = true" />
    <i-table
      :title="$tc('measure', 1)"
      :headers="headers"
      app="base_config.soldby"
      api="sold"
      :reload.sync="reload"
      @click:create="open()"
      @click:edit="open($event)"
    >
      <template v-slot:deleteBtn="{ item, remove }">
        <i-btn
          v-if="!item.static && item.productCount === 0"
          :title="$tc('delete', 1)"
          outlined
          classes="mx-1"
          icon="fa-trash"
          color="error"
          @click="remove(item.pk)"
        />
        <span v-else></span>
      </template>
    </i-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import dialsold from '../../components/sold/modal.vue'
export default {
  components: {
    dialsold
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('name'),
          value: 'name'
        },
        {
          text: this.$t('abbr'),
          value: 'abbr'
        },
        {
          text:
            this.soldbyBtn.delete || this.soldbyBtn.edit
              ? this.$t('action')
              : '',
          value: this.soldbyBtn.delete || this.soldbyBtn.edit ? 'actions' : ''
        }
      ]
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    soldbyBtn() {
      return {
        delete:
          this.getPermissions([`base_config.delete_soldby`]) || this.isAdmin,
        edit: this.getPermissions([`base_config.change_soldby`]) || this.isAdmin
      }
    }
  },
  data() {
    return {
      reload: false,
      dialog: false,
      toEdit: null
    }
  },
  methods: {
    open(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog = true
    }
  }
}
</script>
